#login-background{
    background-image: url("https://gestion.grinbalb.com/login.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25%;
  }
  
  #login-form{
    margin-top: 150px;
  }

.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.cards.content{
    padding: 0 !important;
}

.row-right{
    text-align: right;
}

.opTable.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.header.content{
    height: 72px;
    border-bottom: 3px solid #EBEBEB !important;
}

.header.rows.left{
    padding-left: 32px;
    width: 50% !important;
}

.header.rows.right{
    padding-right: 32px;
    text-align: right;
}

.buttonBlue{
    background-color: #39aede !important;
    border-color: #39aede !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.buttonBlue:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.buttonBlue:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuNotActive{
    text-decoration: none;
    background-color: #39AEDE !important;
    padding-top: 9px;
    padding-bottom: 11px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.menuNotActive:focus{
    text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuNotActive:hover{
    text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuActive{
    text-decoration: none;
    background-color: #0D72B5 !important;
    padding-top: 9px;
    padding-bottom: 11px;
}

.cover.content.rows.title{
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.cover.content.rows.element{
    width: calc(100% - 50px);
    text-align: left;
    display: inline !important;
}

.cover.content.rows.searchbtn{
    margin-top: -1px !important
}

.linkBlue{
    color: #39aede !important;
    text-decoration: none !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.linkBlue:focus{
    color: #0D72B5 !important;
}

.linkBlue:hover{
    color: #0D72B5 !important;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  
.dropzone:focus {
border-color: #2196f3;
}

.dropzone.disabled {
opacity: 0.6;
}

.react-datepicker__navigation.react-datepicker__navigation--previous{
    margin-top: 7px !important;
}

.react-datepicker__navigation.react-datepicker__navigation--next{
    margin-top: 7px !important;
}

.react-datepicker__triangle{
    left: -20px !important;
}

.content-table.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.file.element{
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.header.rows.left50{
    padding-left: 32px;
    width: 50%;
}

.middle-vertical{
    margin-top: auto !important;
    margin-bottom: auto;
}

.content-table.head{
    text-align: center;
    border-bottom: 2px solid #A0A0A0;
    background-color: #EBEBEB;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
  }
  
  .content-table.rows{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 2px solid #EBEBEB;
  }
  
  .content-table.rows.middle{
    text-align: center;
  }
  
  .content-table.rows.right{
    text-align: right;
  }
  
  .content-table.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
  }

  .align-center-vertically{
    display: flex;
    align-items: center;
  }

  .report{
        padding-left: 12px;

        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
  }

  .avatar-10{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .avatar-20{
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .avatar-30{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .avatar-40{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .avatar-60{
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  .statusCard{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 108px !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
  }
  
  .statusCard.green{
    border-left: 5px solid #28A745 !important;
  }
  
  .statusCard.yellow{
    border-left: 5px solid #FFC008 !important;
  }
  
  .statusCard.red{
    border-left: 5px solid #DC3545 !important;
  }

  .align-center-vertically-v2{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }