#login-background{
    background-image: url("https://gestion.grinbalb.com/login.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 25%;
  }
  
  #login-form{
    margin-top: 150px;
  }

.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.cards.content{
    padding: 0 !important;
}

.row-right{
    text-align: right;
}

.opTable.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.header.content{
    height: 72px;
    border-bottom: 3px solid #EBEBEB !important;
}

.header.rows.left{
    padding-left: 32px;
    width: 50% !important;
}

.header.rows.right{
    padding-right: 32px;
    text-align: right;
}

.buttonBlue{
    background-color: #39aede !important;
    border-color: #39aede !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.buttonBlue:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.buttonBlue:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuNotActive{
    text-decoration: none;
    background-color: #39AEDE !important;
    padding-top: 9px;
    padding-bottom: 11px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.menuNotActive:focus{
    -webkit-text-decoration: "none";
            text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuNotActive:hover{
    -webkit-text-decoration: "none";
            text-decoration: "none";
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.menuActive{
    text-decoration: none;
    background-color: #0D72B5 !important;
    padding-top: 9px;
    padding-bottom: 11px;
}

.cover.content.rows.title{
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}

.cover.content.rows.element{
    width: calc(100% - 50px);
    text-align: left;
    display: inline !important;
}

.cover.content.rows.searchbtn{
    margin-top: -1px !important
}

.linkBlue{
    color: #39aede !important;
    text-decoration: none !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.linkBlue:focus{
    color: #0D72B5 !important;
}

.linkBlue:hover{
    color: #0D72B5 !important;
}

.dropzone {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  
.dropzone:focus {
border-color: #2196f3;
}

.dropzone.disabled {
opacity: 0.6;
}

.react-datepicker__navigation.react-datepicker__navigation--previous{
    margin-top: 7px !important;
}

.react-datepicker__navigation.react-datepicker__navigation--next{
    margin-top: 7px !important;
}

.react-datepicker__triangle{
    left: -20px !important;
}

.content-table.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.file.element{
    position: relative;
    /* display: flex; */
    /* align-items: center; */
    width: 100%;
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}

.header.rows.left50{
    padding-left: 32px;
    width: 50%;
}

.middle-vertical{
    margin-top: auto !important;
    margin-bottom: auto;
}

.content-table.head{
    text-align: center;
    border-bottom: 2px solid #A0A0A0;
    background-color: #EBEBEB;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
  }
  
  .content-table.rows{
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 2px solid #EBEBEB;
  }
  
  .content-table.rows.middle{
    text-align: center;
  }
  
  .content-table.rows.right{
    text-align: right;
  }
  
  .content-table.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
  }

  .align-center-vertically{
    display: flex;
    align-items: center;
  }

  .report{
        padding-left: 12px;

        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
  }

  .avatar-10{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .avatar-20{
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  .avatar-30{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .avatar-40{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .avatar-60{
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  .statusCard{
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
    height: 108px !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
  }
  
  .statusCard.green{
    border-left: 5px solid #28A745 !important;
  }
  
  .statusCard.yellow{
    border-left: 5px solid #FFC008 !important;
  }
  
  .statusCard.red{
    border-left: 5px solid #DC3545 !important;
  }

  .align-center-vertically-v2{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
.cards{
    border: none !important;
    box-shadow: 1px 3px 5px #CFCFCF !important;
}

.cards.content{
    padding: 0 !important;
}

.opTable.head{
    text-align: center;
}

.opTable.rows{
    text-align: center;
}

.opTable.status{
    padding: 12px;
    font-size: 1rem;
    font-weight: 400 !important;
}

.header.content{
    height: 72px;
    border-bottom: 3px solid #EBEBEB !important;
}

.header.rows.left{
    padding-left: 32px;
    width: 250px;
}

.header.rows.right{
    padding-right: 32px;
    text-align: right;
}

.buttonBlue{
    background-color: #39aede !important;
    border-color: #39aede !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.buttonBlue:focus{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}

.buttonBlue:hover{
    background-color: #0D72B5 !important;
    border-color: #0D72B5 !important;
}
/**
 * FixedDataTable v1.2.17 
 *
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 */

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCellGroupLayout
 */

 .fixedDataTableCellGroupLayout_cellGroup {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    white-space: nowrap;
  }
  
  .fixedDataTable_isRTL .fixedDataTableCellGroupLayout_cellGroup {
    right: 0;
    left: auto;
  }
  
  .fixedDataTableCellGroupLayout_cellGroup > .public_fixedDataTableCell_main {
    display: inline-block;
    vertical-align: top;
    white-space: normal;
  }
  
  .fixedDataTableCellGroupLayout_cellGroupWrapper {
    position: absolute;
    top: 0;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableCellLayout
   */
  
  .fixedDataTableCellLayout_main {
    border-right-style: solid;
    border-right-width: 1px;
    border-width: 0 1px 0 0;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    position: absolute;
    white-space: normal;
  }
  
  .fixedDataTable_isRTL .fixedDataTableCellLayout_main {
    border-right-width: 0;
    border-left-style: solid;
    border-left-width: 1px;
  }
  
  .fixedDataTableCellLayout_lastChild {
    border-width: 0 1px 1px 0;
  }
  
  .fixedDataTableCellLayout_alignRight {
    text-align: right;
  }
  
  .fixedDataTableCellLayout_alignCenter {
    text-align: center;
  }
  
  .fixedDataTableCellLayout_wrap {
    display: table-cell;
    vertical-align: middle;
    box-sizing: border-box;
  }
  
  .fixedDataTableCellLayout_wrap1 {
    display: table;
  }
  
  .fixedDataTableCellLayout_wrap2 {
    display: table-row;
  }
  
  .fixedDataTableCellLayout_wrap3 {
    display: table-cell;
    vertical-align: middle;
  }
  
  .fixedDataTableCellLayout_columnResizerContainer {
    position: absolute;
    right: 0px;
    width: 6px;
    z-index: 1;
  }
  
  .fixedDataTable_isRTL .fixedDataTableCellLayout_columnResizerContainer {
    left: 0px;
    right: auto;
  }
  
  .fixedDataTableCellLayout_columnResizerContainer:hover {
    cursor: ew-resize;
  }
  
  .fixedDataTableCellLayout_columnResizerContainer:hover .fixedDataTableCellLayout_columnResizerKnob {
    visibility: visible;
  }
  
  .fixedDataTableCellLayout_columnResizerKnob {
    position: absolute;
    right: 0px;
    visibility: hidden;
    width: 4px;
  }
  
  .fixedDataTable_isRTL .fixedDataTableCellLayout_columnResizerKnob {
    left: 0px;
    right: auto;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableColumnResizerLineLayout
   */
  
  .fixedDataTableColumnResizerLineLayout_mouseArea {
    cursor: ew-resize;
    position: absolute;
    right: -5px;
    width: 12px;
  }
  
  .fixedDataTable_isRTL .fixedDataTableColumnResizerLineLayout_mouseArea  {
    right: auto;
    left: -5px;
  }
  
  .fixedDataTableColumnResizerLineLayout_main {
    border-right-style: solid;
    border-right-width: 1px;
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    pointer-events: none;
  }
  
  .fixedDataTable_isRTL .fixedDataTableColumnResizerLineLayout_main {
    border-right-width: 0;
    border-left-style: solid;
    border-left-width: 1px;
  }
  
  .fixedDataTableColumnResizerLineLayout_hiddenElem {
    display: none !important;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableLayout
   */
  
  .fixedDataTableLayout_main {
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
  }
  
  .fixedDataTableLayout_header,
  .fixedDataTableLayout_hasBottomBorder {
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  
  .fixedDataTableLayout_footer .public_fixedDataTableCell_main {
    border-top-style: solid;
    border-top-width: 1px;
  }
  
  .fixedDataTableLayout_topShadow,
  .fixedDataTableLayout_bottomShadow {
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  
  .fixedDataTableLayout_bottomShadow {
    margin-top: -4px;
  }
  
  .fixedDataTableLayout_rowsContainer {
    overflow: hidden;
    position: relative;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableRowLayout
   */
  
  .fixedDataTableRowLayout_main {
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    top: 0;
  }
  
  .fixedDataTableRowLayout_body {
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
  }
  
  .fixedDataTableRowLayout_rowExpanded {
    box-sizing: border-box;
    left: 0;
    position: absolute;
  }
  
  .fixedDataTableRowLayout_fixedColumnsDivider {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-left-style: solid;
    border-left-width: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
  }
  
  .fixedDataTable_isRTL .fixedDataTableRowLayout_fixedColumnsDivider {
    border-left-width: 0;
    border-right-style: solid;
    border-right-width: 1px;
  }
  
  .fixedDataTableRowLayout_columnsShadow {
    position: absolute;
    width: 4px;
  }
  
  .fixedDataTableRowLayout_columnsRightShadow {
    right: 1px;
  }
  
  .fixedDataTable_isRTL .fixedDataTableRowLayout_columnsRightShadow {
    left: 1px;
    right: auto;
  }
  
  .fixedDataTableRowLayout_rowWrapper {
    position: absolute;
    top: 0;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule ScrollbarLayout
   */
  
  .ScrollbarLayout_main {
    box-sizing: border-box;
    outline: none;
    overflow: hidden;
    position: absolute;
    -webkit-user-select: none;
            user-select: none;
  }
  
  .ScrollbarLayout_mainVertical {
    bottom: 0;
    right: 0;
    top: 0;
    width: 15px;
  }
  
  .ScrollbarLayout_mainHorizontal {
    height: 15px;
    left: 0;
    transition-property: background-color height;
  }
  
  /* Touching the scroll-track directly makes the scroll-track bolder */
  .ScrollbarLayout_mainHorizontal.public_Scrollbar_mainActive,
  .ScrollbarLayout_mainHorizontal:hover {
    height: 17px;
  }
  
  .ScrollbarLayout_face {
    left: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    transition-duration: 250ms;
    transition-timing-function: ease;
    transition-property: width;
  }
  
  /**
   * This selector renders the "nub" of the scrollface. The nub must
   * be rendered as pseudo-element so that it won't receive any UI events then
   * we can get the correct `event.offsetX` and `event.offsetY` from the
   * scrollface element while dragging it.
   */
  .ScrollbarLayout_face:after {
    border-radius: 6px;
    content: '';
    display: block;
    position: absolute;
    transition: background-color 250ms ease;
  }
  
  .ScrollbarLayout_faceHorizontal {
    bottom: 0;
    left: 0;
    top: 0;
  }
  
  .ScrollbarLayout_faceHorizontal:after {
    bottom: 4px;
    left: 0;
    top: 4px;
    width: 100%;
  }
  
  .fixedDataTable_isRTL .ScrollbarLayout_faceHorizontal,
  .fixedDataTable_isRTL .ScrollbarLayout_faceHorizontal:after {
    right: 0;
    left: auto;
  }
  
  .ScrollbarLayout_faceHorizontal.public_Scrollbar_faceActive:after,
  .ScrollbarLayout_main:hover .ScrollbarLayout_faceHorizontal:after {
    bottom: calc(4px/2);
  }
  
  .ScrollbarLayout_faceVertical {
    left: 0;
    right: 0;
    top: 0;
  }
  
  .ScrollbarLayout_faceVertical:after {
    height: 100%;
    left: 4px;
    right: 4px;
    top: 0;
  }
  
  .ScrollbarLayout_main:hover .ScrollbarLayout_faceVertical:after,
  .ScrollbarLayout_faceVertical.public_Scrollbar_faceActive:after {
    left: calc(4px/2);
    right: calc(4px/2);
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTable
   *
   */
  
  /**
   * Table.
   */
  .public_fixedDataTable_main {
    border-color: #d3d3d3;
  }
  
  .public_fixedDataTable_header,
  .public_fixedDataTable_hasBottomBorder {
    border-color: #d3d3d3;
  }
  
  .public_fixedDataTable_header .public_fixedDataTableCell_main {
    font-weight: bold;
  }
  
  .public_fixedDataTable_header,
  .public_fixedDataTable_scrollbarSpacer,
  .public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-color: #f6f7f8;
    background-image: linear-gradient(#fff, #efefef);
  }
  
  .public_fixedDataTable_scrollbarSpacer {
    position: absolute;
    z-index: 99;
    top: 0;
  }
  
  .public_fixedDataTable_footer .public_fixedDataTableCell_main {
    background-color: #f6f7f8;
    border-color: #d3d3d3;
  }
  
  .public_fixedDataTable_topShadow {
    background-image: linear-gradient(180deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
  }
  
  .public_fixedDataTable_bottomShadow {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
  }
  
  .public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {
    background-color: #fff;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableCell
   */
  
  /**
   * Table cell.
   */
  .public_fixedDataTableCell_main {
    background-color: #fff;
    border-color: #d3d3d3;
  }
  
  .public_fixedDataTableCell_highlighted {
    background-color: #f4f4f4;
  }
  
  .public_fixedDataTableCell_cellContent {
    padding: 8px;
  }
  
  .public_fixedDataTableCell_columnResizerKnob {
    background-color: #0284ff;
  }
  
  .public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_wrap1 .public_fixedDataTableCell_cellContent {
    margin-left: 12px;
  }
  .public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_wrap.public_fixedDataTableCell_cellContent {
    padding-left: 20px;
  }
  
  .fixedDataTable_isRTL .public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_wrap1 .public_fixedDataTableCell_cellContent {
    margin-left: auto;
    margin-right: 12px;
  }
  
  .fixedDataTable_isRTL .public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_wrap.public_fixedDataTableCell_cellContent {
    padding-right: 20px;
  }
  /**
   * Column reorder goodies.
   */
  .fixedDataTableCellLayout_columnReorderContainer {
    border-color: #0284ff;
    background-color: rgba(0,0,0,0.1);
    width: 12px;
    margin-right: -12px;
    float: left;
    cursor: move;
  }
  
  .fixedDataTable_isRTL .fixedDataTableCellLayout_columnReorderContainer {
    margin-right: auto;
    margin-left: -12px;
    float: right;
  }
  
  .fixedDataTableCellLayout_columnReorderContainer:after {
      content: '::';
      position: absolute;
      top: 50%;
      left: 1px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
  }
  
  .fixedDataTable_isRTL .fixedDataTableCellLayout_columnReorderContainer:after {
    left: auto;
    right: 1px;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableColumnResizerLine
   *
   */
  
  /**
   * Column resizer line.
   */
  .public_fixedDataTableColumnResizerLine_main {
    border-color: #0284ff;
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule fixedDataTableRow
   */
  
  /**
   * Table row.
   */
  .public_fixedDataTableRow_main {
    background-color: #fff;
  }
  
  .public_fixedDataTableRow_highlighted,
  .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: #f6f7f8;
  }
  
  .public_fixedDataTableRow_fixedColumnsDivider {
    border-color: #d3d3d3;
  }
  
  .public_fixedDataTableRow_columnsShadow {
    background-image: linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
  }
  
  .fixedDataTable_isRTL .public_fixedDataTableRow_columnsShadow {
    background-image: linear-gradient(270deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
  }
  
  .public_fixedDataTableRow_columnsRightShadow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  /**
   * Copyright Schrodinger, LLC
   * All rights reserved.
   *
   * This source code is licensed under the BSD-style license found in the
   * LICENSE file in the root directory of this source tree. An additional grant
   * of patent rights can be found in the PATENTS file in the same directory.
   *
   * @providesModule Scrollbar
   *
   */
  
  /**
   * Scrollbars.
   */
  
  /* Touching the scroll-track directly makes the scroll-track bolder */
  .public_Scrollbar_main.public_Scrollbar_mainActive,
  .public_Scrollbar_main {
    background-color: #fff;
    border-left: 1px solid #d3d3d3;
  }
  
  .fixedDataTable_isRTL .public_Scrollbar_main.public_Scrollbar_mainActive,
  .fixedDataTable_isRTL .public_Scrollbar_main {
    border-right: 1px solid #d3d3d3;
    border-left-width: 0;
  }
  
  .public_Scrollbar_mainOpaque,
  .public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
  .public_Scrollbar_mainOpaque:hover {
    background-color: #fff;
  }
  
  .public_Scrollbar_face:after {
    background-color: #c2c2c2;
  }
  
  .public_Scrollbar_main:hover .public_Scrollbar_face:after,
  .public_Scrollbar_mainActive .public_Scrollbar_face:after,
  .public_Scrollbar_faceActive:after {
    background-color: #7d7d7d;
  }
  
